import React from "react";

// ui
import { SignInView } from "@/views/auth/signin/SignInView";
import { AuthLayout } from "@/layouts/AuthLayout";
import { isUserAuthenticated } from "@/libs/isUserAuthenticated";

export default function LoginPage() {
  return (
    <AuthLayout>
      <SignInView />
    </AuthLayout>
  );
}

export const getServerSideProps = async (ctx) => {
  const [isAuth] = await isUserAuthenticated(ctx);

  // use fake URL base domain to construct URL object and extract decoded search params
  const { searchParams } = new URL(`http://localhost:3000${ctx.resolvedUrl}`);

  // if user is authenticated
  if (isAuth) {
    return {
      redirect: {
        // redirect to "to" search param route or default (/app/accounts)
        destination: searchParams.get("to") ?? "/app/accounts",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};
